<template>
  <div>
    <div class="introduction_module">
      <div class="i_m_title1">江苏南通人力资源服务产业园</div>
      <div class="i_m_title2">JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK</div>
      <p>
        江苏南通人力资源服务产业园位于南通市崇川区工农北路150号，是南通市“为民办实事”重点建设工程。产业园以“建设苏中一流人力资源市场，打造长三角人力资源服务产业”为目标，紧紧围绕“集聚产业，拓展业态，孵化企业，培育市场”的功能定位，逐步打造人力资源产业生态链，为南通经济社会高质量发展提供强有力的人才智力支撑。
      </p>
      <img src="../../assets/img/park/tu.png" class="i_m_img" />
    </div>
    <div class="introduce_module">
      <img src="../../assets/img/park/title1.png" class="i_m_title" />
      <p>
          园区分为地面主楼16层，裙楼5层，建筑面积4.3万平方米，占地面积13745平方米，建筑高度74.25米。
        </p>
         <p>
         裙楼1层供举办线上线下招聘活动，建筑面积约为4000平方米，分AB两区，能同时容纳140家用人单位进场招聘。园区每周举办4场线下招聘，每周二举办高校毕业生专场招聘会。同时园区以一体化智慧平台反映全省各地市招聘会举办情况、月供职位供给情况、求职人员分布情况。
        </p>
        <p>
            裙楼2层为事务代理公共服务窗口，可提供社保代理、档案管理、就业推荐、退休代办等服务。
        </p>
         <p>
            裙楼3层为公共就业与人才服务窗口，为高层次人才、高校毕业生、就业创业者等提供相关政策咨询。
        </p>
         <p>
            主楼4-16层为办公室配套用房，园区具有得天独厚的区位优势，附近文体中心、商业中心、医院、银行、公园氧吧、健身馆、图书馆一应俱全，同时园区配套了员工食堂、培训教室、会议室、多功能报告厅、咖啡吧、众创空间等，可实现拎包入驻。
        </p>
      <img src="../../assets/img/park/tu2.png" class="i_m_img" />
    </div>
    <div class="expect_module">
      <img src="../../assets/img/park/title2.png" class="i_m_title" />
      <p>
        江苏南通人力资源服务产业园以南通人力资源大厦为核心，整合各类优质资源，着力打造四大平台。一是着力打造产业服务平台；二是着力打造企业服务平台；三是着力打造创业服务平台；四是着力打造就业服务平台。产业园将建成省级甚至国家级人力资源产业园，建成长三角地区最为优秀的人力资源产业园之一。
      </p>
    </div>
    <div class="map_module">
      <img src="../../assets/img/park/title3.png" class="i_m_title" />
      <address-map></address-map>
    </div>
    <park-info-footer></park-info-footer>
  </div>
</template>

<script>
import ParkInfoFooter from '@/components/ParkInfoFooter'
import AddressMap from '@/components/AddressMap'

export default {
  name: 'Introduction',
  components: {
    ParkInfoFooter,
    AddressMap
  }
}
</script>

<style lang="less" scoped>
.introduction_module {
  padding: 82px 18px 0px;
  height: 659.5px;
  background: url('~@/assets/img/park/introduction_bg.png');
  background-size: cover;
  color: #ffffff;
  font-family: 'Source Han Sans CN';

  .i_m_title1 {
    font-weight: 500;
    font-size: 24px;
  }

  .i_m_title2 {
    margin-top: 8px;
    font-weight: 400;
    font-size: 9.2px;
    font-family: 'Arial';
  }

  p {
    margin-top: 25px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.8px;
  }

  .i_m_img {
    width: 317px;
    height: 190px;
    margin: 0px 11px;
  }
}

.introduce_module {
  padding: 28px 18px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Han Sans CN';

  .i_m_title {
    width: 175.5px;
    height: 22px;
    margin-bottom: 25px;
  }

  p {
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.8px;
  }

  .i_m_img {
    margin-top: 25px;
    width: 301.5px;
    height: 301.5px;
    border: 1px dashed #afb0b2;
    margin-bottom: 30px;
  }
}

.expect_module {
  padding: 0px 18px;
  height: 579px;
  background: url('~@/assets/img/park/park.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .i_m_title {
    margin-top: 113px;
    margin-bottom: 25px;
    width: 175.5px;
    height: 21.5px;
  }

  p {
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.8px;
    color: #ffffff;
  }
}

.map_module {
  display: flex;
  flex-direction: column;
  align-items: center;

  .i_m_title {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 175.5px;
    height: 21.5px;
  }

  map {
    width: 100%;
    height: 289px;
  }
}
</style>
