var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c(
        "div",
        { staticClass: "map_module" },
        [
          _c("img", {
            staticClass: "i_m_title",
            attrs: { src: require("../../assets/img/park/title3.png") }
          }),
          _c("address-map")
        ],
        1
      ),
      _c("park-info-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "introduction_module" }, [
      _c("div", { staticClass: "i_m_title1" }, [
        _vm._v("江苏南通人力资源服务产业园")
      ]),
      _c("div", { staticClass: "i_m_title2" }, [
        _vm._v("JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK")
      ]),
      _c("p", [
        _vm._v(
          " 江苏南通人力资源服务产业园位于南通市崇川区工农北路150号，是南通市“为民办实事”重点建设工程。产业园以“建设苏中一流人力资源市场，打造长三角人力资源服务产业”为目标，紧紧围绕“集聚产业，拓展业态，孵化企业，培育市场”的功能定位，逐步打造人力资源产业生态链，为南通经济社会高质量发展提供强有力的人才智力支撑。 "
        )
      ]),
      _c("img", {
        staticClass: "i_m_img",
        attrs: { src: require("../../assets/img/park/tu.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "introduce_module" }, [
      _c("img", {
        staticClass: "i_m_title",
        attrs: { src: require("../../assets/img/park/title1.png") }
      }),
      _c("p", [
        _vm._v(
          " 园区分为地面主楼16层，裙楼5层，建筑面积4.3万平方米，占地面积13745平方米，建筑高度74.25米。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 裙楼1层供举办线上线下招聘活动，建筑面积约为4000平方米，分AB两区，能同时容纳140家用人单位进场招聘。园区每周举办4场线下招聘，每周二举办高校毕业生专场招聘会。同时园区以一体化智慧平台反映全省各地市招聘会举办情况、月供职位供给情况、求职人员分布情况。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 裙楼2层为事务代理公共服务窗口，可提供社保代理、档案管理、就业推荐、退休代办等服务。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 裙楼3层为公共就业与人才服务窗口，为高层次人才、高校毕业生、就业创业者等提供相关政策咨询。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 主楼4-16层为办公室配套用房，园区具有得天独厚的区位优势，附近文体中心、商业中心、医院、银行、公园氧吧、健身馆、图书馆一应俱全，同时园区配套了员工食堂、培训教室、会议室、多功能报告厅、咖啡吧、众创空间等，可实现拎包入驻。 "
        )
      ]),
      _c("img", {
        staticClass: "i_m_img",
        attrs: { src: require("../../assets/img/park/tu2.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "expect_module" }, [
      _c("img", {
        staticClass: "i_m_title",
        attrs: { src: require("../../assets/img/park/title2.png") }
      }),
      _c("p", [
        _vm._v(
          " 江苏南通人力资源服务产业园以南通人力资源大厦为核心，整合各类优质资源，着力打造四大平台。一是着力打造产业服务平台；二是着力打造企业服务平台；三是着力打造创业服务平台；四是着力打造就业服务平台。产业园将建成省级甚至国家级人力资源产业园，建成长三角地区最为优秀的人力资源产业园之一。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }